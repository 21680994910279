<template>
    <div>
        <div class="self-news" v-for="(item,index) in dataInfoArr" :key="index">
            <div class="self-news-state" v-if="item.closeState === '01'">欠费停水</div>
            <div class="self-news-user">
                <div class="self-news-user-name">{{item.realName}} 户号：{{item.userNo}}</div>
                <div class="self-news-user-address van-multi-ellipsis--l3">
                    <em class="info-customer-label" v-if="item.signName && item.signName.length > 0">{{item.signName}}</em>
                    <span class="info-customer-news">{{item.userAddress}}</span>
                </div>
            </div>
            <div class="self-news-notes">
                <div class="self-news-notes-left">
                    <span>{{item.readCycle}}抄表</span>
                    <div v-if="item.showStatus === '01'"><span>{{item.readDays}}日</span>后抄表</div>
                </div>
                <div class="self-news-notes-right">
                    <div class="snnr-read" @click="readRecord(item.userNo)">抄表记录</div>
                    <div class="snnr-meter" v-if="item.showStatus === '00'" @click="readMeter(index)">立即抄表</div>
                </div>
            </div>
            <div class="self-news-cost" v-if="item.arrears">
                <div>
                    <div class="self-news-cost-money">未缴账单： ¥{{item.arrears}}</div>
                    <!-- <div>
                        <van-checkbox
                            class="self-news-cost-checkbox"
                            v-model="item.checked"
                            shape="square"
                            icon-size="15px"
                        >
                            预存可抵：¥{{item.preDepositBuckle}}
                        </van-checkbox>
                    </div> -->
                </div>
                <div>
                    <van-button class="self-news-cost-button" plain type="info" @click="immediatelyPay(item.userNo)">
                        立即缴费
                    </van-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        dataInfoArr: {
            type: Array,
            require: true,
            default: () => {
                return [];
            }
        },
    },
    data() {
        return {
            selfState: true, // 是否可以抄表
            oweState: true, // 欠费状态
            checked: false
        };
    },
    mounted() {
        // console.log('AA--数据', this.dataInfoArr)
    },
    methods: {
        readRecord(userNo){
            this.$router.push({
                name: 'MeterReadingRecord',
                path: '/SelfMeterRead/meterReadingRecord',
                query: { userNo }
            })
        },
        readMeter(index) {
            this.$router.push({
                path: '/SelfMeterRead/selfMeterReadImmediately',
                name: 'SelfMeterReadImmediately',
                query: {
                  index
                }
            })
        },
        immediatelyPay(userNo){
            this.$router.push({
                name: 'PaymentPage',
                path: '/QueryPay/PaymentPage',
                query: {
                  wuserNo: userNo
                }
            })
        }
    }
};
</script>

<style lang="less" scoped>
.self-news {
  margin: 16px;
  background: #ffffff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  box-shadow: 0 6px 16px 0 rgba(63, 70, 79, 0.12);
  &-state {
    position: absolute;
    top: 0;
    right: 0;
    padding: 1px 6px;
    background: #f64357;
    border-radius: 0 8px 0 8px;
    font-family: PingFangSC-Medium;
    font-size: 12px;
    color: #ffffff;
    letter-spacing: -0.3px;
  }
  &-user {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 16px;
    display: block;
    &-icon {
      color: #0e6bf9;
      margin-right: 6px;
    }
    &-tag {
      font-family: PingFangSC-Medium;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.65);
      margin-right: 6px;
    }
    &-division {
      color: #aeaeae;
      margin-right: 6px;
    }
    &-name {
      font-family: PingFangSC-Regular;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.85);
      font-weight: 700;
    }
    &-address {
        padding-top: 2px;
        font-family: PingFangSC-Regular;
        font-size: 14px;
        color: #000000;
        em {
            font-style: normal;
            background: #ECF1FE;
            border-radius: 2px;
            border-radius: 2px;
            font-family: PingFangSC-Regular;
            font-size: 12px;
            color: #637FB7;
            letter-spacing: 0;
            line-height: 16px;
            padding: 1px 5px;
            margin-right: 5px;
        }
    }
  }

  &-notes {
    padding: 0 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    &-left {
      display: flex;
      font-family: PingFangSC-Regular;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.6);
      div {
        margin-left: 6px;
      }
      div > span {
        color: #f78b4d;
      }
    }
    &-right {
      display: flex;
      font-family: PingFangSC-Regular;
      font-size: 14px;
      color: #1d6fe9;
      .snnr-read {
        padding: 16px 8px 16px 16px;
      }
      .snnr-meter {
        padding: 16px 0 16px 8px;
      }
    }
  }
  .self-news-notes::after {
    position: absolute;
    box-sizing: border-box;
    content: ' ';
    pointer-events: none;
    right: 16px;
    top: 0;
    left: 16px;
    border-top: 1px solid #ebedf0;
    -webkit-transform: scaleY(.5);
    transform: scaleY(.5);
  }
  &-cost {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 16px 16px 16px;
    position: relative;
    &-money {
      font-family: PingFangSC-Regular;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.85);
    }
    /deep/ .self-news-cost-checkbox .van-checkbox__label {
      font-family: PingFangSC-Regular;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.85);
    }
    &-button {
      border-radius: 4px;
      // width: 80px;
      height: 32px;
      font-family: PingFangSC-Regular;
      font-size: 12px;
      color: #1d6fe9;
    }
  }
  .self-news-cost::after {
      position: absolute;
    box-sizing: border-box;
    content: ' ';
    pointer-events: none;
    right: 16px;
    top: 0;
    left: 16px;
    border-top: 1px solid #ebedf0;
    -webkit-transform: scaleY(.5);
    transform: scaleY(.5);
  }
}
</style>
